import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Box,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import AuthenticationLayout from "components/authenticationLayout";
import ErrorDialogBox from "components/dialogBoxes/errorDialog";
import Footer from "components/footer";
import { SLLoader } from "components/loader";
import { SLButton } from "components/slButton";
import { InputStandardField } from "components/slTextField";
import { SLTypography } from "components/slTypography";
import { SLVerificationMessage } from "components/verificationMessage";
import { Formik } from "formik";
import { ArrowBackIosNewIcon } from "helpers/enum/constants";
import { checkValidity } from "helpers/validations";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { makeSLAPICall } from "utils/slRTKHelper/axiosConfigs";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import "./index.css";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])((?=.*[!@#$%^&*-_+=?~`|/:;“’"'()[]{}\\ ])|(?=.*[0-9])).{8,}$/,
      "Password must contain at least 8 characters, one upper case, lower character, one number and special character"
    )
    .required("Fill the obligatory field, please"),
  confirmPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref("password")], "Passwords do not match")
    .required("Fill the obligatory field, please"),
});

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const deviceId = uuidv4();
  const query = new URLSearchParams(location.search);
  const hash = query.get("hash");
  const email = query.get("email");

  const [newPassword, setNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [savedsucessfully, setSavedSuccessfully] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [errorDialog, setErrorDialog] = useState(false);
  const [navigateURL, setnavigateURL] = useState("");
  const [loader, setloader] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { data, loading, error } = useSelector(
    SLSelector.patchAuthResetPassword
  );
  const { loading: userLoginLoading, error: userLoginError } = useSelector(
    SLSelector.postAuthLoginWeb
  );
  const handleClickShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = (): void => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    if (userLoginError) {
      setErrorDialog(true);
      setValidationError(userLoginError);
    }
  }, [userLoginError]);

  useEffect(() => {
    if (data) {
      setSavedSuccessfully(true);
      setTimeout(() => {
        setSavedSuccessfully(false);
      }, 2000);
      dispatch(SLThunk.patchAuthResetPassword.reset());
      dispatch(
        SLThunk.postAuthLoginWeb.call({
          email: email!,
          password: newPassword,
          fcmToken: deviceId,
          platform: "web",
          deviceInfo: deviceId,
        })
      );
    }
    if (error) {
      setErrorDialog(true);
      setValidationError(error);
    }
  }, [data, error]);

  useEffect(() => {
    if (!hash || !email) {
      navigate("/login");
    }
  }, []);

  const validate = (values: any) => {
    if (checkValidity(values.password, email)) {
      setPasswordError("The password doesn’t confirm to security requirements");
      return false;
    } else {
      return true;
    }
  };

  const resetPasswordForm = (values: any) => {
    dispatch(
      SLThunk.patchAuthResetPassword.call({
        password: values.password,
        confirmPassword: values.confirmPassword,
        fcmToken: deviceId,
        platform: "web",
        deviceInfo: deviceId,
        hash: hash ?? "",
        email: email ?? "",
      })
    );
  };

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  useEffect(() => {
    const validateUrl = async () => {
      try {
        const makeCallObj = {
          method: "post",
          url: "/auth/validate-forgot-password-link",
          urlParams: {},
          payload: { hash: hash?.toString() ?? "", email: email ?? "" },
          params: {},
        };
        const response: any = await makeSLAPICall(makeCallObj);
        if (response != undefined) {
          setErrorDialog(true);
          setValidationError(response?.message[0]);
          setnavigateURL("/login");
        } else {
          setErrorDialog(false);
          setloader(false);
        }
      } catch (error) {
        setErrorDialog(true);
        setValidationError(error + "");
        setnavigateURL("/login");
      }
    };
    validateUrl();
  }, []);

  const handleSubmit = async (values: any) => {
    if (!validate(values)) {
      return;
    } else {
      resetPasswordForm(values);
    }
  };

  return (
    <div className="page-container resetPasswordContainer">
      <AuthenticationLayout
        pageTitle="Reset Password"
        colorChange="background-color-change"
      >
        <Container
          sx={{
            width: { xl: "93.2%", lg: "95%" },
            minHeight: "100vh",
          }}
          maxWidth={false}
          className="footer-page-wrapper"
        >
          <Stack>
            <div
              style={{
                marginTop: "4%",
                marginBottom: "5%",
                display: "flex",
              }}
            >
              <ArrowBackIosNewIcon
                aria-label="Back Navigation"
                titleAccess="Back Navigation"
                tabIndex={0}
                sx={{ cursor: "pointer", color: "var(--sl-deep-purple)" }}
                onClick={() => navigate("/login")}
                onKeyPress={() => navigate("/login")}
              />
              <Divider
                sx={{
                  height: 40,
                  borderColor: "var(--sl-deep-purple)",
                  opacity: "0.2",
                  marginLeft: "2%",
                  marginTop: "-10px",
                  marginRight: "2%",
                }}
                orientation="vertical"
              />
              <SLTypography
                variant="heading"
                tabIndex={0}
                aria-level={2}
                color="text-deepPurple"
                messageId="general.reset.password.message"
              />
            </div>

            <Grid container sx={{ marginTop: "1%" }}>
              <Grid item lg={6} md={9} sm={12}>
                {loader ? null : (
                  <>
                    <Box
                      sx={{
                        color: "black",
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "21.79px",
                        marginBottom: "3%",
                      }}
                    >
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        validateOnChange={false}
                        validateOnBlur={false}
                      >
                        {({
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          values,
                          setErrors,
                        }) => (
                          <form autoComplete="off" onSubmit={handleSubmit}>
                            <InputStandardField
                              onChange={(e: any) => {
                                setPasswordError("");
                                handleChange(e);
                                setNewPassword(e.target.value);
                                setErrors({ password: "" });
                              }}
                              onBlur={handleBlur("password")}
                              error={Boolean(
                                touched.password && errors.password
                              )}
                              helperText={
                                (touched.password && errors.password) ||
                                passwordError
                              }
                              value={values.password}
                              id="password"
                              variant="filled"
                              props={{
                                className: "forgot-password-email-input",
                              }}
                              labelId="New Password"
                              type={showPassword ? "text" : "password"}
                              inputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onKeyPress={handleClickShowPassword}
                                      edge="end"
                                    >
                                      {!showPassword ? (
                                        <VisibilityOffOutlinedIcon
                                          sx={{
                                            color: "var(--sl-deep-purple)",
                                          }}
                                        />
                                      ) : (
                                        <VisibilityOutlinedIcon
                                          sx={{
                                            color: "var(--sl-deep-purple)",
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <InputStandardField
                              onChange={(e: any) => {
                                handleChange(e);
                                setErrors({ confirmPassword: "" });
                              }}
                              onBlur={handleBlur("confirmPassword")}
                              error={Boolean(
                                touched.confirmPassword &&
                                  errors.confirmPassword
                              )}
                              helperText={
                                touched.confirmPassword &&
                                errors.confirmPassword
                              }
                              value={values.confirmPassword}
                              id="confirmPassword"
                              variant="filled"
                              labelId="Confirm New Password"
                              type={showConfirmPassword ? "text" : "password"}
                              props={{
                                className: "forgot-password-email-input",
                              }}
                              inputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowConfirmPassword}
                                      onKeyPress={
                                        handleClickShowConfirmPassword
                                      }
                                      edge="end"
                                    >
                                      {!showConfirmPassword ? (
                                        <VisibilityOffOutlinedIcon
                                          sx={{
                                            color: "var(--sl-deep-purple)",
                                          }}
                                        />
                                      ) : (
                                        <VisibilityOutlinedIcon
                                          sx={{
                                            color: "var(--sl-deep-purple)",
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <FormControl
                              variant="standard"
                              sx={{ marginTop: "30px" }}
                              fullWidth
                            >
                              <SLButton
                                type="submit"
                                messageId={"general.createNewPassword"}
                                variant="contained"
                                sx={{
                                  width: { lg: "40%", md: "40%", xs: "60%" },
                                }}
                              />
                            </FormControl>
                          </form>
                        )}
                      </Formik>
                    </Box>
                    <SLTypography
                      variant="smallBodyCopy"
                      color="text-deepPurple"
                      tabIndex={0}
                    >
                      <FormattedMessage id="general.reset.password.more.guide" />{" "}
                      <Link
                        to="/app/contact-us"
                        tabIndex={0}
                        aira-level={2}
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                          color: "var(--sl-purple)",
                        }}
                      >
                        <FormattedMessage id="general.reset.password.support" />
                      </Link>
                    </SLTypography>
                  </>
                )}
              </Grid>
            </Grid>
          </Stack>
        </Container>

        <div className="sticky-bottom-footer">
          <Footer />
        </div>
        <SLLoader open={loading} />
        <SLLoader open={userLoginLoading} />
      </AuthenticationLayout>
      <ErrorDialogBox
        errorDialog={errorDialog}
        setErrorDialog={setErrorDialog}
        errorMessage={validationError}
        errorHeaderMessage="Oops!"
        navigateUrl={navigateURL}
      />
      <SLVerificationMessage
        open={savedsucessfully}
        message={"Password Saved"}
      />
    </div>
  );
};

export default ResetPassword;
