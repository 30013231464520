import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SLTypography } from "components/slTypography";
import { Formik } from "formik";
import { checkValidity } from "helpers/validations";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import * as Yup from "yup";
import ErrorDialogBox from "../../../dialogBoxes/errorDialog";
import { SLLoader } from "../../../loader";
import { SLButton } from "../../../slButton";
import { InputStandardField } from "../../../slTextField";
import { SLVerificationMessage } from "../../../verificationMessage";
import "./index.css";

const useStyles = makeStyles((theme: any) => ({
  paper: {
    width: "400px",
  },
}));

interface ChangePasswordProps {
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
}

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Fill the obligatory field, please"),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])((?=.*[!@#$%^&*-_+=?~`|/:;“’"'()[]{}\\ ])|(?=.*[0-9])).{8,}$/,
      "Password must contain at least 8 characters, one upper case, lower character, one number and special character"
    )
    .required("Fill the obligatory field, please"),
  confirmPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref("password")], "Passwords do not match")
    .required("Fill the obligatory field, please"),
});

const ChangePasswordModal = ({
  openDialog,
  setOpenDialog,
}: ChangePasswordProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [validationError, setValidationError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [savedsucessfully, setSavedSuccessfully] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const { data: userInfo } = useSelector(SLSelector.getUserProfile);
  const { data, loading, error } = useSelector(
    SLSelector.patchUserChangePassword
  );

  useEffect(() => {
    if (data) {
      dispatch(SLThunk.patchUserChangePassword.reset());
      setOpenDialog(false);
      setSavedSuccessfully(true);
      setTimeout(() => {
        setSavedSuccessfully(false);
      }, 2000);
    }
    if (error) {
      setErrorDialog(true);
      setValidationError(error);
    }
  }, [data, error]);

  const handlechnagePassword = (values: any) => {
    dispatch(
      SLThunk.patchUserChangePassword.call({
        password: values.password,
        oldPassword: values.oldPassword,
        confirmPassword: values.confirmPassword,
      })
    );
  };

  const validate = (values: any) => {
    if (checkValidity(values.password, userInfo?.email)) {
      setPasswordError("The password doesn’t confirm to security requirements");
      return false;
    } else {
      return true;
    }
  };

  const handleCancel = () => {
    setOpenDialog(false);
  };

  const initialValues = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
  };

  const handleSubmit = async (values: any) => {
    if (!validate(values)) {
      return;
    } else {
      handlechnagePassword(values);
    }
  };

  return (
    <>
      <Grid container id="change-password">
        <Grid item xs={12} lg={9} md={9}>
          <Dialog
            fullWidth
            maxWidth="xs"
            open={openDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ borderRadius: "10px" }}
            classes={{ paper: classes.paper }}
          >
            <Box className="changeDialog-box">
              <SLTypography
                tabIndex={0}
                variant="subhead"
                color="text-deepPurple"
              >
                Change Password
              </SLTypography>{" "}
              <div style={{ marginTop: "2%", marginBottom: "2%" }}>
                <SLTypography
                  tabIndex={0}
                  variant="extraSmall"
                  color="text-light"
                  marginTop="2%"
                  sx={{ lineHeight: "21px" }}
                >
                  To help protect your account, we recommend a strong password
                  with a combination of upper and lowercase letters, numbers,
                  and symbols.
                </SLTypography>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={false}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  setErrors,
                }) => (
                  <form autoComplete="off" onSubmit={handleSubmit}>
                    <Stack>
                      <InputStandardField
                        onChange={(e: any) => {
                          handleChange(e);
                          setErrors({ oldPassword: "" });
                        }}
                        onBlur={handleBlur("oldPassword")}
                        error={Boolean(
                          touched.oldPassword && errors.oldPassword
                        )}
                        helperText={touched.oldPassword && errors.oldPassword}
                        value={values.oldPassword}
                        id="oldPassword"
                        labelId="Current Password"
                        type={showOldPassword ? "text" : "password"}
                        variant="filled"
                        props={{
                          className:
                            "forgot-password-email-input placeholder-width",
                        }}
                        inputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setShowOldPassword(!showOldPassword)
                                }
                                onKeyPress={() =>
                                  setShowOldPassword(!showOldPassword)
                                }
                                edge="end"
                              >
                                {!showOldPassword ? (
                                  <VisibilityOffOutlinedIcon
                                    sx={{ color: "var(--sl-deep-purple)" }}
                                  />
                                ) : (
                                  <VisibilityOutlinedIcon
                                    sx={{ color: "var(--sl-deep-purple)" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <InputStandardField
                        onChange={(e: any) => {
                          handleChange(e);
                          setErrors({ password: "" });
                        }}
                        onBlur={handleBlur("password")}
                        error={Boolean(touched.password && errors.password)}
                        helperText={
                          (touched.password && errors.password) || passwordError
                        }
                        value={values.password}
                        id="password"
                        labelId="New Password"
                        type={showPassword ? "text" : "password"}
                        variant="filled"
                        props={{
                          className:
                            "forgot-password-email-input placeholder-width",
                        }}
                        inputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                onKeyPress={() =>
                                  setShowPassword(!showPassword)
                                }
                                edge="end"
                              >
                                {!showPassword ? (
                                  <VisibilityOffOutlinedIcon
                                    sx={{ color: "var(--sl-deep-purple)" }}
                                  />
                                ) : (
                                  <VisibilityOutlinedIcon
                                    sx={{ color: "var(--sl-deep-purple)" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          onChange: (e: any) => {
                            handleChange(e);
                            setPasswordError("");
                          },
                        }}
                      />
                      <InputStandardField
                        onChange={(e: any) => {
                          handleChange(e);
                          setErrors({ confirmPassword: "" });
                        }}
                        onBlur={handleBlur("confirmPassword")}
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                        value={values.confirmPassword}
                        id="confirmPassword"
                        labelId="Confirm New Password"
                        type={showConfirmPassword ? "text" : "password"}
                        variant="filled"
                        props={{
                          className:
                            "forgot-password-email-input placeholder-width",
                        }}
                        inputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                onKeyPress={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                edge="end"
                              >
                                {!showConfirmPassword ? (
                                  <VisibilityOffOutlinedIcon
                                    sx={{ color: "var(--sl-deep-purple)" }}
                                  />
                                ) : (
                                  <VisibilityOutlinedIcon
                                    sx={{ color: "var(--sl-deep-purple)" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <SLButton
                        type="submit"
                        disabled={
                          userInfo?.registerType === "email" ? false : true
                        }
                        sx={
                          userInfo?.registerType === "email"
                            ? { marginTop: "4%" }
                            : { opacity: 0.5, marginTop: "4%" }
                        }
                        messageId={"general.savePassword"}
                      />
                      <SLTypography
                        tabIndex={0}
                        onKeyPress={() => handleCancel()}
                        onClick={() => handleCancel()}
                        color="text-purple"
                        sx={{
                          textDecoration: "underline",
                          paddingLeft: "43%",
                          marginTop: "3%",
                          cursor: "pointer",
                        }}
                        messageId={"general.cancel"}
                      />
                    </Stack>
                  </form>
                )}
              </Formik>
              <SLLoader open={loading} />
            </Box>
          </Dialog>
        </Grid>
      </Grid>

      <ErrorDialogBox
        errorDialog={errorDialog}
        setErrorDialog={setErrorDialog}
        errorMessage={validationError}
        errorHeaderMessage="Oops!"
      />
      <SLVerificationMessage
        open={savedsucessfully}
        message={"New Password was setup"}
      />
    </>
  );
};

export default ChangePasswordModal;
