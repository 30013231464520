export const validateEmail = (email: string) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

// The password doesn’t conform to security requirements

export const validatePassword = (password: string) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*\\-_+=?~`|\\\\/:;\"""“'’'‘()\\[\\]{} ])(?=.*[0-9]).{8,}$/;
  // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,322}$/;
  const passwordRegex = regex.test(password);
  return passwordRegex;
};

export const checkValidity = (password: any, email: any) => {
  const useremail = email.trim().toLowerCase();
  const userpassword = password.trim().toLowerCase();
  const threeParts = email.match(/.{3}/g) || [];
  const allThreeParts: any[] = threeParts.concat(
    useremail.slice(1).match(/.{3}/g) ?? [],
    useremail.slice(2).match(/.{3}/g) ?? []
  );
  return new RegExp(allThreeParts.join("|"), "i").test(userpassword);
};
